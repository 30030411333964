<template>
  <div class="container">
    <div class="row top-container">
      <admin-navbar />
      <div class="col-xs-12 col-sm-8">
        <h2>Report files</h2>
      </div>
    </div>
    <div class="row justify-content-center dashboard-cotainer">
      <div class="col-xs-12">
        <div class="row">
          <div class="col-xs-12">
            <router-link
              class="btn btn-success btn-sm add-file-button"
              to="/upload-file"
              >Upload report file</router-link
            >
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <table class="table">
              <thead>
                <tr>
                  <th>Reference</th>
                  <th>Name</th>
                  <th>Time created</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(file, id) in files" :key="id">
                  <td>{{ file.reference }}</td>
                  <td>{{ file.fileName }}</td>
                  <td>{{ file.timeCreated.toDate() }}</td>
                  <td>
                    <button
                      class="btn btn-danger btn-sm right-button"
                      v-on:click="removeFile(id, file)"
                    >
                      Remove
                    </button>
                    <router-link
                      :to="`/print-qr/${file.reference}`"
                      class="btn btn-info btn-sm right-button button-qr-print"
                    >
                      Print QR
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="col-xs-12 page-buttons">
              <button
                class="btn btn-sm btn-primary"
                v-if="firstPageButton"
                @click="firstPage"
              >
                Page 1
              </button>
              <button
                class="btn btn-sm btn-primary"
                v-if="nextPageButton"
                @click="nextPage"
              >
                &gt;&gt;
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import app from "../../firebase";
import "firebase/storage";
import "firebase/firestore";
import { mapGetters } from "vuex";
import store from "../../store";
import AdminNavbar from "../../components/AdminNavbar.vue";

const db = app.firestore();

export default {
  name: "Dashboard",
  data() {
    return {
      qrValue: "",
      firstVisible: null,
      lastVisible: null,
      pageLimit: 50,
      nextPageButton: false,
      firstPageButton: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
      files: "files",
    }),
  },
  components: {
    AdminNavbar,
  },
  methods: {
    removeFile(id, file) {
      if (!confirm("Remove this report entry?")) {
        return;
      }

      let storageRef = app.storage().ref();
      let fileRef = storageRef.child(`reports/${file.fileName}`);

      // Delete the file
      fileRef
        .delete()
        .then(() => {
          db.collection("reports")
            .doc(id)
            .delete()
            .then(() => {
              store.dispatch("removeFileById", id);
            })
            .catch((error) => {
              console.error("Error removing document: ", error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getUrl(ref) {
      let storageRef = app.storage().ref(ref);
      let $this = this;
      storageRef.getDownloadURL().then((url) => {
        $this.qrValue = url;
      });
    },
    logout() {
      app
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace("/login");
        });
    },
    nextPage() {
      db.collection("reports")
        .orderBy("timeCreated")
        .startAfter(this.lastVisible)
        .limit(this.pageLimit)
        .get()
        .then((snapshot) => {
          this.firstPageButton = true;
          this.lastVisible = snapshot.docs[snapshot.docs.length - 1];
          this.nextPageButton = snapshot.docs.length === this.pageLimit;
          store.dispatch("addFiles", []);
          snapshot.forEach((doc) => {
            store.dispatch("addFile", { id: doc.id, data: doc.data() });
          });
        });
    },
    firstPage() {
      db.collection("reports")
        .orderBy("timeCreated")
        .startAt(1)
        .limit(this.pageLimit)
        .get()
        .then((snapshot) => {
          this.firstPageButton = false;
          this.lastVisible = snapshot.docs[snapshot.docs.length - 1];
          this.nextPageButton = snapshot.docs.length === this.pageLimit;
          store.dispatch("addFiles", []);
          snapshot.forEach((doc) => {
            store.dispatch("addFile", { id: doc.id, data: doc.data() });
          });
        });
    },
  },

  created() {
    db.collection("reports")
      .orderBy("timeCreated")
      .limit(this.pageLimit)
      .get()
      .then((snapshot) => {
        this.firstVisible = snapshot.docs[0];
        this.lastVisible = snapshot.docs[snapshot.docs.length - 1];
        this.nextPageButton = snapshot.docs.length === this.pageLimit;
        snapshot.forEach((doc) => {
          store.dispatch("addFile", { id: doc.id, data: doc.data() });
        });
      });
  },
};
</script>

<style scoped>
div.page-buttons {
  text-align: right;
}
.button-qr-print {
  margin-right: 4px;
}
button,
a {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold !important;
}
.right-button {
  float: right;
}
.top-container {
  margin-bottom: 24px;
}
</style>
