<template>
  <div class="row">
    <div class="col-xs-12">
      <div
        id="carouselExampleSlidesOnly"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img
              src="../assets/slide1.jpg"
              class="d-block w-100"
              alt="Slide 1"
            />
          </div>
          <div class="carousel-item">
            <img
              src="../assets/slide2.jpg"
              class="d-block w-100"
              alt="Slide 2"
            />
          </div>
          <div class="carousel-item">
            <img
              src="../assets/slide3.jpg"
              class="d-block w-100"
              alt="Slide 3"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
