<template>
  <div class="container upload-container">
    <div class="row top-container">
      <admin-navbar />
      <div class="col-xs-12 col-sm-8 offset-sm-2">
        <h2>Upload report file</h2>
      </div>
    </div>
    <div class="row upload-container">
      <div class="col-sm-8 offset-sm-2">
        <div class="card">
          <div class="card-header">{{ statusMessage }}&nbsp;</div>
          <div class="card-body">
            <!-- <div v-if="error" class="alert alert-danger">{{ error }}</div> -->
            <form action="#" @submit.prevent="submit">
              <div class="form-group row file-upload-row">
                <label
                  for="reference"
                  class="col-md-2 col-form-label text-md-right"
                  >Reference
                </label>
                <div class="col-md-3 reference-inupt-container">
                  <input
                    id="reference"
                    type="reference"
                    class="form-control"
                    name="reference"
                    value
                    required
                    autofocus
                    v-model="reference"
                  />
                </div>
              </div>

              <div class="form-group row file-upload-row">
                <label for="file" class="col-md-2 col-form-label text-md-right"
                  >File
                </label>

                <div class="col-md-6 login-input">
                  <input
                    id="file"
                    type="file"
                    class="form-control"
                    name="file"
                    required
                    @change="fileChanged"
                  />
                </div>
              </div>

              <div class="form-group row mb-0">
                <div class="col-md-1 offset-md-10 login-input">
                  <button
                    type="submit"
                    class="btn btn-sm btn-primary"
                    @click="uploadFile"
                  >
                    Upload
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import app from "../../firebase";
import firebase from "firebase/app";

import "firebase/storage";
import "firebase/firestore";

import AdminNavbar from "../../components/AdminNavbar.vue";

export default {
  name: "UploadFile",
  data() {
    return {
      statusMessage: "",
      reference: null,
      fileUrl: null,
      fileName: null,
      fileToUpload: null,
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
      files: "files",
    }),
  },
  components: {
    AdminNavbar,
  },
  methods: {
    getUrl(ref) {
      let storageRef = app.storage().ref(ref);
      let $this = this;
      storageRef.getDownloadURL().then((url) => {
        $this.qrValue = url;
      });
    },
    fileChanged(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.fileName = files[0].name;
        if (this.fileName.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.fileUrl = fr.result;
          this.fileToUpload = files[0];
        });
      }
    },
    uploadFile() {
      if (!this.reference || this.reference.length < 1) {
        this.statusMessage = "Please provide reference";

        return;
      }
      if (!this.fileToUpload) {
        this.statusMessage = "Please select file!";

        return;
      }
      let storageRef = app.storage().ref();
      let nameForUpload = this.reference + "_" + this.fileName;
      let newRef = storageRef.child(`reports/${nameForUpload}`);
      let db = app.firestore();

      db.collection("reports")
        .where("reference", "==", this.reference)
        .get()
        .then((entry) => {
          if (
            !entry.empty &&
            confirm(
              "Report file for this reference number alredy exists. Overwrite?"
            )
          ) {
            newRef.put(this.fileToUpload).then((snapshot) => {
              snapshot.ref.getDownloadURL().then((downloadURL) => {
                db.collection("reports")
                  .doc(entry.docs[0].id)
                  .update({
                    downloadURL: downloadURL,
                    fileName: nameForUpload,
                    timeCreated: firebase.firestore.Timestamp.fromDate(
                      new Date()
                    ),
                  });
                this.$router.replace({ name: "Dashboard" });
              });
            });

            return;
          }

          newRef.put(this.fileToUpload).then((snapshot) => {
            snapshot.ref.getDownloadURL().then((downloadURL) => {
              db.collection("reports")
                .add({
                  reference: this.reference,
                  downloadURL: downloadURL,
                  fileName: nameForUpload,
                  timeCreated: firebase.firestore.Timestamp.fromDate(
                    new Date()
                  ),
                })
                .then(() => {
                  this.$router.replace({ name: "Dashboard" });
                })
                .catch((error) => {
                  console.log(error);
                });
            });
          });
        });
    },
    logout() {
      app
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace("/login");
        });
    },
  },
};
</script>

<style scoped>
button {
  font-weight: bold !important;
}
.right-button {
  float: right;
}
.file-upload-row {
  padding: 6px;
}
.reference-inupt-container {
  padding-left: 4px;
}
.top-container {
  margin-bottom: 24px;
}
</style>
