<template>
  <div class="container">
    <div class="row top-container">
      <admin-navbar />
      <div class="col-xs-12 col-sm-8">
        <h2>Jewellery Collection</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <router-link
          class="btn btn-success btn-sm add-file-button"
          to="/create-jewellery-item"
          >New Item</router-link
        >
      </div>
    </div>
    <div class="row justify-content-center dashboard-cotainer">
      <div class="col-xs-12">
        <div class="row">
          <div class="col-xs-12">
            <table class="table">
              <thead>
                <tr>
                  <th></th>
                  <th class="type-column">Type</th>
                  <th class="name-column">Name</th>
                  <th class="desc-column">Desription</th>
                  <th class="price-column">Price</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, id) in availableItems" :key="id">
                  <td class="image-column">
                    <router-link :to="'/edit-jewellery-item/' + id">
                      <img :src="item.imageUrl" alt="item-image" />
                    </router-link>
                  </td>
                  <td>{{ item.type }}</td>
                  <td>
                    <router-link :to="'/edit-jewellery-item/' + id">{{
                      item.name
                    }}</router-link>
                  </td>
                  <td>{{ item.description }}</td>
                  <td>&pound;{{ item.price }}</td>
                  <td>
                    <span v-if="item.sold"
                      >Sold<br />Order ID: {{ item.orderID }}</span
                    >
                    <span v-else>Available</span>
                  </td>
                  <td>
                    <button
                      type="button"
                      class="btn btn-sm btn-close"
                      @click="removeItem(id, item.imageName)"
                    ></button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import app from "../../firebase";
import "firebase/storage";
import "firebase/firestore";

import AdminNavbar from "../../components/AdminNavbar.vue";

let storageRef = app.storage().ref();
const db = app.firestore();

export default {
  name: "ManageJewellery",
  data() {
    return {
      availableItems: {},
    };
  },
  components: {
    AdminNavbar,
  },
  methods: {
    removeItem(itemId, itemImageName) {
      if (!confirm("This item will be permanently deleted, continue?")) {
        return;
      }
      db.collection("jewellery_items")
        .doc(itemId)
        .delete()
        .then(() => {
          let fileRef = storageRef.child(`jewellery/${itemImageName}`);
          // Delete the file
          fileRef.delete().catch((error) => {
            console.log(error);
          });

          let items = {};
          for (let availableItemId in this.availableItems) {
            if (availableItemId !== itemId) {
              items[availableItemId] = this.availableItems[availableItemId];
            }
          }
          this.availableItems = items;
        });
    },
    formatDate(date) {
      return `${date.toLocaleString("en-GB", { timeZone: "UTC" })}`;
    },
  },
  created() {
    let db = app.firestore();
    db.collection("jewellery_items")
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          this.availableItems[doc.id] = doc.data();
        });
      });
  },
};
</script>

<style scoped>
.button-qr-print {
  margin-right: 4px;
}
button,
a {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold !important;
}
.right-button {
  float: right;
}
.type-column {
  width: 15%;
}
.name-column {
  width: 30%;
}
.desc-column {
  width: 40%;
}
.price-column {
  width: 15%;
}
.top-container {
  margin-bottom: 24px;
}
.image-column img {
  width: 85px;
}
</style>
