<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light admin-navbar">
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ms-auto">
        <li class="nav-item">
          <router-link class="nav-link" to="/manage-jewellery"
            >Jewellery</router-link
          >
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/print-sapphire-certificate"
            >Sapphire Certificate</router-link
          >
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/dashboard"
            >Report Files</router-link
          >
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/client-messages"
            >Messages</router-link
          >
        </li>
        <li class="nav-item">
          <a @click="logout()" class="nav-link" href="#"> Logout</a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import app from "../../firebase";

export default {
  name: "AdminNavbar",
  methods: {
    logout() {
      app
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace("/login");
        });
    },
  },
};
</script>

<style scoped>
div.page-buttons {
  text-align: right;
}
.button-qr-print {
  margin-right: 4px;
}
button,
a {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold !important;
}
.admin-navbar {
  margin-bottom: 24px;
}
</style>
