<template>
  <div class="home">
    <MainHeader />
    <div class="container jewellry-collection-container">
      <h1 class="main-title">Jewellery Collection</h1>
      <hr />
      <p class="info-paragraph">
        Our number one goal is to make incomparably beautiful jewellery, just
        for you.<br />At our design studio, our team designs every piece to
        delight you, from the first time you see it and every day after.<br />We
        carefully consider the entire piece quality. We obsess over comfort,
        quality, and affordability,<br />to ensure that this is jewellery you
        will cherish for a lifetime.<br />We do bespoke design as well with
        exceptional quality stones to meet your expectations.<br />We
        specialised in coloured stones emeralds, sapphires, rubies and other
        rare gems.<br />Items in our jewellery collection section are already
        made, brand new,<br />ready to make someone special incredibly happy.
      </p>
      <div class="row justify-content-center">
        <div
          v-for="(item, key) in jewelleryItems"
          :key="key"
          class="col-xs-12 col-sm-4 col-md-3 col-lg-2 item-container"
        >
          <div class="image-holder" :id="key">
            <router-link :to="'/jewellery-item/' + key">
              <img
                alt="jewellery-item"
                :src="item.imageUrl"
                class="jewellery-item-image"
              />
            </router-link>
          </div>
          <div class="item-text-holder">
            <h2 class="jewellery-item-title">{{ item.name }}</h2>
            <p class="jewellery-item-type">{{ item.type }}</p>

            <p class="jewellery-item-text">
              <router-link :to="'/jewellery-item/' + key" class="read-more-link"
                >details</router-link
              >
            </p>
            <p
              v-if="item.price"
              class="jewellery-item-text jewellery-item-price"
            >
              Price: &pound;{{ item.price }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import MainHeader from "@/components/MainHeader";
import app from "../firebase";
import "firebase/firestore";

export default {
  name: "Home",
  data() {
    return {
      itemsLimit: 50,
      availableItems: {},
      lastItem: null,
      priorLastItem: null,
    };
  },
  computed: {
    jewelleryItems() {
      return this.availableItems;
    },
  },
  components: {
    MainHeader,
  },
  methods: {
    getOffset(el) {
      const rect = el.getBoundingClientRect();
      return {
        left: rect.left + window.scrollX,
        top: rect.top,
      };
    },
    handleScroll() {
      if (this.lastItem === null) {
        return;
      }
      let lastImage = document.getElementById(this.lastItem.id);
      if (lastImage === null) {
        return;
      }
      if (this.getOffset(lastImage).top < 260) {
        this.nextPage();
      }
    },
    nextPage() {
      let db = app.firestore();
      db.collection("jewellery_items")
        .orderBy("timeCreated")
        .limit(this.itemsLimit)
        .startAfter(this.lastItem)
        .get()
        .then((snapshot) => {
          let counter = 0;
          snapshot.forEach((doc) => {
            counter++;
            this.availableItems[doc.id] = doc.data();
            this.lastItem = doc;
          });
          if (counter < this.itemsLimit) {
            this.lastItem = null;
          }
        });
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    let db = app.firestore();
    app
      .auth()
      .signInAnonymously()
      .then(() => {
        db.collection("jewellery_items")
          .orderBy("timeCreated")
          .limit(this.itemsLimit)
          .get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              const thisDoc = doc.data();

              if (thisDoc.sold === undefined || thisDoc.sold === false) {
                this.availableItems[doc.id] = thisDoc;
                this.lastItem = doc;
              }
            });
          });
      });
  },
};
</script>

<style scoped>
p,
a,
h1,
h2,
h3,
h4,
h5 {
  font-family: "Noto Serif", sans-serif;
  text-align: center;
  display: block;
}

h2.jewellery-item-title {
  font-size: 12pt !important;
  height: 38px;
}

.services-section {
  padding: 15px;
}

.info-paragraph {
  padding: 15px;
  text-align: center;
}

.jewellry-collection-container {
  padding-top: 12px;
}

.jewellery-item-image {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  max-width: 142px;
  max-height: 142px;
}

h2.jewellery-item-title {
  font-size: 12pt;
  text-align: center;
  margin-bottom: 2px;
}

p.jewellery-item-type {
  font-size: 10pt;
  text-align: center;
  margin-bottom: 2px;
}

p.jewellery-item-text {
  font-size: 10pt;
  text-align: center;
  margin-bottom: 2px;
}

div.image-holder {
  display: block;
  position: relative;
  width: 100%;
  height: 164px;
  margin-bottom: 6px;
}

div.item-text-holder {
  width: 100%;
}

.jewellery-item-type,
.jewellery-item-price {
  font-weight: bold;
}

.jewellery-item-type {
  text-transform: capitalize;
}

.main-title {
  text-align: center;
}

.read-more-link {
  font-size: 9pt;
}

.jewellery-items-container {
  text-align: center;
}

@media only screen and (min-width: 1400px) {
  .jewellery-item-image {
    max-width: 164px;
  }
}
</style>
