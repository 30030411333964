<template>
  <div class="body">
    <div class="header">
        <table class="header-table">
            <tr>
                <td class="logo-cell">
                    <img src="../../assets/logo_main.png" alt="Logo" class="logo-image"/>
                </td>
                <td>
                    <img src="../../assets/logo_text.png" alt="Logo" class="logo-text"/>
                </td>
                <td class="info-table-cell">
                    <table class="heder-info-table">
                        <tr>
                            <td class="info-table-first-cell">Verify this report at SGJ-gems.com</td>
                        </tr>
                        <tr>
                            <td class="colored-cell">SGJ GEMOLOGIGAL REPORT</td>
                        </tr>
                        <tr>
                            <td class="report-title-cell">
                                <input value="SAPPHIRE REPORT"
                                    class="report-name-input"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>SGJ REPORT<span><input
                                    type="text"
                                    class="report-number-input"
                                /></span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input class="date-picker" placeholder="DATE" />
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>
    </div>

    <div class="mt-body">
        <div class="main-tables-holder main-table-left">
            <div class="table-title-holder">DETAILS</div>
            <table class="main-table">
                <tbody>
                    <tr>
                        <td><input class="details-label" v-on:created="calculateInputWidth()" value="Shape"/></td>
                        <td class="middle-cell"></td>
                        <td>
                            <input type="text"
                                class="details-input"
                                v-on:keyup="calculateInputWidth"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
            <table class="main-table">
                <tbody>
                    <tr>
                        <td>Cutting Style: Crown</td>
                        <td class="middle-cell"></td>
                        <td>
                            <input type="text"
                                class="details-input"
                                v-on:keyup="calculateInputWidth"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
            <table class="main-table">
                <tbody>
                    <tr>
                        <td>Cutting Style: Pavilion</td>
                        <td class="middle-cell"></td>
                        <td>
                            <input type="text"
                                class="details-input"
                                v-on:keyup="calculateInputWidth"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
            <table class="main-table">
                <tbody>
                    <tr>
                        <td>Transparency</td>
                        <td class="middle-cell"></td>
                        <td>
                            <input type="text"
                                class="details-input"
                                v-on:keyup="calculateInputWidth"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
            <table class="main-table">
                <tbody>
                    <tr>
                        <td>Color</td>
                        <td class="middle-cell"></td>
                        <td>
                            <input type="text"
                                class="details-input"
                                v-on:keyup="calculateInputWidth"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="main-tables-holder push-bottom">
            <div class="table-title-holder">RESULTS</div>
            <table class="main-table">
                <tbody>
                    <tr>
                        <td>Species</td>
                        <td class="middle-cell"></td>
                        <td>
                            <input type="text"
                                class="details-input"
                                v-on:keyup="calculateInputWidth"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
            <table class="main-table">
                <tbody>
                    <tr>
                        <td>Variety</td>
                        <td class="middle-cell"></td>
                        <td>
                            <input type="text"
                                class="details-input"
                                v-on:keyup="calculateInputWidth"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
            <table class="main-table">
                <tbody>
                    <tr>
                        <td>Geographic Origin</td>
                        <td class="middle-cell"></td>
                        <td>
                            <input type="text"
                                class="details-input"
                                v-on:keyup="calculateInputWidth"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
            <table class="main-table">
                <tbody>
                    <tr>
                        <td class="main-table-td-high">TREATMENT<span
                            class="treatment-subtext">(Scan QR code for more information)</span>
                        </td>
                    </tr>
                    <tr>
                        <td class="main-table-td-high"><input type="text"
                            class="details-input extended-input"
                            v-on:keyup="calculateInputWidth"
                            value="Heated"
                        /></td>
                        <br />
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="secondary-table-holder">
            <table>
                <tr>
                    <td class="secondary-table-first-td">Item Description:</td>
                    <td class="secondary-table-second-td">
                        <input type="text" class="details-input detail-input-secondary"/>
                    </td>
                </tr>
                <tr>
                    <td class="secondary-table-first-td">Weight:</td>
                    <td class="secondary-table-second-td">
                        <input type="text" class="details-input detail-input-secondary" />
                    </td>
                </tr>
                <tr>
                    <td class="secondary-table-first-td">Measurements:</td>
                    <td class="secondary-table-second-td">
                        <input type="text" class="details-input detail-input-secondary" />
                    </td>
                </tr>
                <tr>
                    <td class="secondary-table-first-td">Comments:</td>
                    <td class="secondary-table-second-td">
                        <textarea rows="5" class="details-input detail-input-secondary"></textarea>
                    </td>
                </tr>
            </table>
        </div>

        <div class="image-holder">
            <table>
                <tr>
                    <td class="image-holder-cell">
                        <div class="image-holder-div">
                            <img id="preview" style="display: none;" />
                        </div>
                    </td>
                </tr>
            </table>
        </div>

        <div class="third-table-holder">
            <table >
                <tr>
                    <td class="third-table-first-td">
                        <input type="file" id="fileInput" accept="image/*" />
                        <label for="fileInput" class="select-image">Select file <small>(5cm x 5cm)</small></label>
                        <br />
                        Image is approximate
                    </td>
                    <td class="third-table-second-td">
                        <table class="small-texts-table">
                            <tr>
                                <td colspan="2" class="qr-code-address">
                                    <span>sgj-gems.com</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="small-justified-text">
                                    <span>The results documented in this report refer only to the article described and were obtained using the techniques and uequipment used by SGJ at the time of the examination.</span>
                                </td>
                                <td class="small-qr-code">
                                    <img src="../../assets/sgj-gems.svg" class="qr-image" alt="QR">
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <span class="padlock-box">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lock-fill" viewBox="0 0 16 16">
                                            <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2m3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2"/>
                                        </svg>
                                    </span>
                                    <span class="padlock-text-box">THE SECURITY FEATURES IN THIS DOCUMENT, INCLUDING THE HOLOGRAM, QR CODE AND MICROPRINT LINES. IN ADDITION TO THOSE NOT LISTED. EXCEED DOCUMENT SECURITY INDUSTRY GUIDELINES.</span>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrintCertificate",
  mounted() {
    const fileInput = document.getElementById('fileInput');
    const preview = document.getElementById('preview');

    fileInput.addEventListener('change', (event) => {
        const file = event.target.files[0];
        if (file.type.startsWith('image/')) {
            const url = URL.createObjectURL(file);
            preview.src = url;
            preview.style.display = 'block';
        }
    });
  },
  methods: {
    calculateInputWidth(e) {
        e.target.style.width = (this.getTextWidth(" " + e.target.value)) + "px"
    },
    getTextWidth(text, font) {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        // Use the input font or the default body font
        context.font = font || getComputedStyle(document.body).font;
        const metrics = context.measureText(text);

        // Then only return the width
        return metrics.width;
    }
  }
};
</script>

<style scoped>
    div.body {
        font-family: 'Open Sans';
        padding: .5cm;
        width: 21cm;
        margin: 0 auto;
    }
    table {
        width: 100%;
        border: 0 solid white;
        padding: 0;
        margin: 0 0 6px 0;
    }
    table.main-table td {
        line-height: 3px;
    }
    td {
        padding: 0px;
        text-align: left;
        width: max-content;
        white-space: nowrap;
    }
    td.colored-cell {
        color: white;
        padding: .08cm;
        font-size: 9pt;
        font-weight: bold;
        background-color: #bf7e04;
        line-height: 11pt;
    }
    .logo-image {
        width: 2cm; 
    }
    .logo-text {
        width: 7.1cm; 
    }
    td.logo-cell {
        width: 2.1cm
    }
    td.info-table-cell {
        width: 70%
    }
    td.info-table-first-cell {
        text-align: center;
        font-size: 7pt;
        line-height: 8pt;
    }
    td.middle-cell {
        width: 100%;
        border-bottom: 1px dotted black;
    }
    .details-input {
        border: 0 solid white;
        border-bottom: 1px solid lightgray;
        text-align: right;
        white-space: nowrap;
        width: auto;
    }
    .clearfix {
        float: clear;
    }
    .secondary-table-holder {
        width: 60%;
        float: left;
    }
    .main-tables-holder {
        width: 49%;
        float: left;
    }
    .main-table-left {
        margin-right: 2%;
    }
    .table-title-holder {
        width: 100%;
        margin-bottom: .4cm;
        font-weight: bold;
    }
    .report-title-cell {
        padding-top: .01cm;
        line-height: 14pt;
        font-size: 16pt;
        color: #bf7e04;
    }
    .report-number-input {
        border: 0 solid white;
        border-bottom: 1px solid lightgray;
        text-align: left;
        line-height: 11pt;
        font-size: 11pt;
    }
    .header-table {
        margin-bottom: 1cm;
    }
    .main-table-td-high {
        line-height: 14pt !important
    }
    .treatment-subtext {
        font-size: 8pt
    }
    .extended-input {
        width: 100% !important;
        text-align: left;
        font-weight: normal
    }
    .mt-body {
        width: 100%
    }
    .push-bottom {
        margin-bottom: 2cm;
    }
    .detail-input-secondary {
        width: 100%;
        text-align: left !important;
    }
    .secondary-table-first-td {
        width: 33%;
    }
    .secondary-table-second-td {
        width: 67%;
    }
    .secondary-table-holder {
        height: 7cm;
        overflow-y: visible;
        margin-bottom: 3cm;
    }
    .third-table-first-td {
        vertical-align: top;
        font-size: 9pt;
        font-weight: bold;
        width: 13cm;
        text-align: center;
    }
    td.small-justified-text {
        display: inline-block;
        font-size: 6.4pt;
        overflow-wrap: break-word;
        word-wrap: break-word;
        text-align: justify;
        white-space: break-spaces;
        width: 4.8cm;
        min-height: 1.2cm;
    }
    .qr-image {
        width: 1.7cm
    }
    td.qr-code-address {
        padding-right: .10cm;
        font-size: 7pt;
        text-align: right;
        color: #bf7e04;
    }
    table.small-texts-table {
        width: 6.4cm !important;
    }
    .small-qr-code {
        vertical-align: top
    }
    .padlock-box {
        border: .05cm solid #bf7e04;
        display: block;
        padding: .16cm 0 .12cm .26cm;
        width: 1cm;
        height: 1.1cm;
        float: left;
        background-color: #595b5c
    }
    .padlock-box svg {
        color: #bf7e04;
    }
    .padlock-text-box {
        padding: 0 0 0 0.1cm;
        height: 1cm;
        width: 5.5cm;
        white-space: break-spaces;
        display: block;
        float: left;
        font-size: 5.4pt;
        text-align: justify;
        word-wrap: break-word;
    }
    .date-picker {
        border: none;
        border-bottom: 1px solid red;
        line-height: 11pt;
    }
    .report-name-input {
        padding: 0;
        color: #bf7e04;
    }
    .image-holder {
        height: 5cm;
        width: 100%;
        float: left;
    }
    .image-holder img {
        max-height: 5cm;
    }
    td.image-holder-cell {
        display: block;
        text-align: center;
    }
    div.image-holder-div {
        width: 13cm;
        text-align: center
    }
    img#preview {
        margin: 0 auto;
    }
    input#fileInput {
        display: none
    }
    .select-image {
        display: inline-block;
        padding: 1px 6px;
        border: 1px solid #ccc;
        border-radius: 4px;
        background-color: #f2f2f2;
        cursor: pointer;

        /* Hover and focus styles */
        &:hover,
        &:focus {
            background-color: #ccc;
        }
    }

    input.details-label {
        white-space: nowrap;
        width: auto;
        border: 0 solid white;
    }
    @media print {
        .report-name-input {
            border: none;
        }
        .select-image { 
            display: none;
        }
        body {
            margin: 0;
            padding: 0;
        }
        table {
            width: 100%;
            border: 0 solid white;
            padding: 0;
            margin: 0 0 6px 0;
        }
        td {
            padding: 0px;
            text-align: left;
            width: max-content;
            white-space: nowrap;
        }
        td.middle-cell {
            width: 100%;
            border-bottom: 1px dotted black;
        }
        input.details-label {
            white-space: nowrap;
            width: auto;
            border: 0 solid white;
        }
        .details-input {
            border: 0 solid white;
        }
        .report-number-input {
            border: 0 solid white;
            border-bottom: 1px solid lightgray;
            text-align: left;
            line-height: 11pt;
            font-size: 11pt;
        }
        .main-tables-holder {
            width: 49%;
            float: left;
        }
        .main-table-left {
            margin-right: 2%;
        }
        .table-title-holder {
            width: 100%;
            margin-bottom: .4cm;
        }
        .report-number-input {
            border: 0 solid white;
            text-align: left;
            line-height: 11pt;
            font-size: 11pt;
        }
        table.main-table td {
            line-height: 3px;
        }
        .date-picker {
            border: none;
        }
    }
</style>
