<template>
  <div class="certifiate-section" id="certificate-section">
    <div class="row certificate-section-search">
      <div class="col-sm-12">
        <h2>Report Check</h2>
      </div>
      <div class="col-sm-4 offset-sm-4">
        <div class="input-group mb3">
          <input
            id="reference"
            class="form-control"
            name="reference"
            placeholder="Please enter a reference number"
            value
            required
            v-model="reference"
            aria-label="Recipient's username"
            aria-describedby="basic-addon2"
          />
          <div class="input-group-append">
            <button class="btn btn-outline-success" @click="search">
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="reportNotFound">
      <div class="col-xs-12 col-md-6 offset-md-3">
        <p>Sorry, this reference number does not exist in the system!</p>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-md-6 offset-md-3 report-holder">
        <iframe :src="fileSrc" v-if="fileSrc && fileAvailable" />
      </div>
    </div>
  </div>
</template>

<script>
import app from "../firebase";
import "firebase/firestore";

// import store from "../store";
const db = app.firestore();

export default {
  name: "CertificateSection",
  data() {
    return {
      reference: null,
      fileAvailable: false,
      fileSrc: null,
      reportNotFound: false,
    };
  },
  methods: {
    search() {
      this.fileSrc = "";
      this.fileAvailable = false;
      this.reportNotFound = false;
      if (this.reference === null || this.reference.length < 2) {
        document.querySelector("#reference").focus();

        return;
      }

      app
        .auth()
        .signInAnonymously()
        .then(() => {
          db.collection("reports")
            .where("reference", "==", this.reference)
            .get()
            .then((snapshot) => {
              if (snapshot.empty) {
                this.reportNotFound = true;
                setTimeout(() => {
                  this.reportNotFound = false;
                }, 6000);
                return;
              }

              const certificateData = snapshot.docs[0].data();

              if (certificateData.downloadURL) {
                this.fileSrc = certificateData.downloadURL;
                this.fileAvailable = true;
              }
            });
        });
    },
  },
};
</script>

<style>
.report-holder iframe {
  margin: 0 auto;
  width: 100%;
  height: 1200px;
}
.certifiate-section {
  text-align: center;
  padding: 15px !important;
  background-color: #8affff;
}
.certificate-section-search {
  margin-bottom: 15px;
}
</style>
