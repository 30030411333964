<template>
  <div class="container">
    <div class="row">
      <admin-navbar />
      <div class="col-xs-12 col-sm-8">
        <h2>Messages</h2>
      </div>
    </div>
    <div class="row justify-content-center dashboard-cotainer">
      <div class="col-xs-12">
        <div class="row">
          <div class="col-xs-12">
            <table class="table">
              <thead>
                <tr>
                  <th class="time-column">Time submitted</th>
                  <th class="reference-column">Reference</th>
                  <th class="email-column">Email</th>
                  <th class="message-column">Message</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(message, id) in availableMessages" :key="id" :id="'message-row-' + id">
                  <td>{{ formatDate(message.timeSubmitted.toDate()) }}</td>
                  <td>{{ message.reference }}</td>
                  <td>{{ message.contactEmail }}</td>
                  <td>{{ message.message }}</td>
                  <td><button
                      class="btn btn-danger btn-sm right-button"
                      v-on:click="removeMessage(id)"
                    >
                      Remove
                    </button></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import app from "../../firebase";

import "firebase/storage";
import "firebase/firestore";
import { mapGetters } from "vuex";
import store from "../../store";

const db = app.firestore();

import AdminNavbar from "../../components/AdminNavbar.vue";

export default {
  name: "ClientMessages",
  data() {
    return {
      availableMessages: {},
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
      files: "files",
    }),
  },
  components: {
    AdminNavbar,
  },
  methods: {
    removeMessage(id) {
      if (!confirm("Remove this message entry?")) {
        return;
      }
      
      db.collection("clientMessage")
        .doc(id)
        .delete()
        .then(() => {
          alert("The message has been now removed");
          document.getElementById('message-row-' + id).remove();
        })
        .catch((error) => {
          console.error("Error removing message: ", error);
        });
    },
    formatDate(date) {
      return `${date.toLocaleString("en-GB", { timeZone: "UTC" })}`;
    },
    removeFile(id, file) {
      if (!confirm("Remove this report entry?")) {
        return;
      }

      let storageRef = app.storage().ref();
      let fileRef = storageRef.child(`reports/${file.fileName}`);

      // Delete the file
      fileRef
        .delete()
        .then(() => {
          db.collection("reports")
            .doc(id)
            .delete()
            .then(() => {
              store.dispatch("removeFileById", id);
            })
            .catch((error) => {
              console.error("Error removing document: ", error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getUrl(ref) {
      let storageRef = app.storage().ref(ref);
      let $this = this;
      storageRef.getDownloadURL().then((url) => {
        $this.qrValue = url;
      });
    },
    logout() {
      app
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace("/login");
        });
    },
  },
  created() {
    db.collection("clientMessage")
      .orderBy("timeSubmitted")
      .limit(25)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          this.availableMessages[doc.id] = doc.data();
        });
      });
  },
};
</script>

<style scoped>
.button-qr-print {
  margin-right: 4px;
}
button,
a {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold !important;
}
.right-button {
  float: right;
}
.time-column {
  width: 15%;
}
.reference-column {
  width: 15%;
}
.message-column {
  width: 45%;
}
</style>
