<template>
  <div class="main-header">
    <div class="container-fluid container-heading">
      <div class="row">
        <div class="col-sm-12">
          <a href="tel:+442081449309" class="phone-link">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              fill="currentColor"
              class="bi bi-telephone-fill"
              viewBox="0 0 18 18"
            >
              <path
                fill-rule="evenodd"
                d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
              />
            </svg>
            0208 1449 309
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <router-link to="/" style="text-decoration: none">
            <img src="../assets/logo_8x8in_main_black.png" alt="SGJ" />
            <br />
            <h1 class="main-title">SURREY GEMS AND JEWELS</h1>
            <p class="main-content">GEMMOLOGY &amp; VALUATIONS</p>
          </router-link>
        </div>
      </div>
    </div>
    <nav
      class="navbar navbar-expand-lg navbar-dark bg-dark navbar-custom ml-auto"
    >
      <div class="container-fluid">
        <a class="navbar-brand" href="#"></a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarText"
          aria-controls="navbarText"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarText">
          <ul class="navbar-nav mb-2 mb-lg-0 ms-auto">
            <li class="nav-item">
              <a class="nav-link" href="/#about-us">About Us</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/#certificate-section">Report Check</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/jewellery-collection"
                >Jewellery Collection</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/#contacts">Contacts</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "MainHeader",
};
</script>

<style scoped>
#navbarText .nav-link {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  color: #bdbdbd;
}
.container-heading {
  text-align: center;
  background-color: #0f0f0e;
  padding: 12px 0 0;
}
.container-heading img {
  width: 142px;
}
.navbar-custom {
  background-color: #0f0f0e !important;
  color: #c2a304ff;
}
h1.main-title {
  color: #c2a304ff;
  font-family: serif;
  font-size: 18pt;
  padding: 0 14px;
  margin: 0;
  display: inline-block;
  border-bottom: 1px solid #c2a304ff;
}
.main-content {
  color: #c2a304ff;
  font-family: serif;
  font-size: 12pt;
  padding: 0;
  margin: 0;
}
.phone-link {
  float: right;
  color: rgba(255, 255, 255, 0.75);
  font-size: 1em;
  text-decoration: none;
  margin-right: 24px;
}

@media only screen and (max-width: 976px) {
  .navbar-nav {
    text-align: center !important;
  }
}
</style>
