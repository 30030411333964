<template>
  <div class="print-qr-code">
    <div class="qr-container">
      <qrcode-vue :value="qrValue" v-if="qrValue"></qrcode-vue>
    </div>
    <h2 class="no-print">QR Code ready to print.</h2>
    <router-link class="btn btn-secondary no-print" to="/dashboard"
      >Back</router-link
    >
  </div>
</template>

<script>
import app from "../../firebase";
import "firebase/firestore";
import QrcodeVue from "qrcode.vue";

const db = app.firestore();

export default {
  name: "Dashboard",
  data() {
    return {
      qrValue: null,
    };
  },
  components: {
    QrcodeVue,
  },
  created() {
    if (!this.$route.params.reference) {
      return;
    }
    db.collection("reports")
      .where("reference", "==", this.$route.params.reference)
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          return;
        }

        const certificateData = snapshot.docs[0].data();

        if (certificateData.downloadURL) {
          this.qrValue = certificateData.downloadURL;
          setTimeout(() => {
            window.print();
          }, 1000);
        }
      });
  },
};
</script>

<style>
@media screen {
  .qr-container {
    display: none;
  }
  .print-qr-code {
    padding: 15px;
  }
}
@media print {
  .qr-container {
    position: absolute;
    top: 230mm;
    right: 30mm;
  }
  .no-print,
  .no-print * {
    display: none;
  }
}
</style>
