<template>
  <div class="home">
    <MainHeader />
    <div class="container jewellry-item-container">
      <div class="row action-status-message" v-if="statusMessage.length > 0">
        <div class="col-sm-12">
          <p class="status-message">{{ statusMessage }}</p>
        </div>
      </div>
      <div class="row jewellery-item-content">
        <div class="col-sm-6 offset-sm-3">
          <h1 class="jewellery-item-title">{{ jewelleryItem.name }}</h1>
          <hr />
        </div>
        <div class="col-sm-4 offset-sm-4">
          <img
            :src="jewelleryItem.imageUrl"
            alt="item-image"
            class="jewellery-item-image"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 offset-sm-3 jewellery-item-content">
          <p class="jewellery-type-label">{{ jewelleryItem.type }}</p>
        </div>
        <div class="col-sm-6 offset-sm-3 jewellery-item-content">
          <p class="jewellery-description-label">Description</p>
          <hr class="jewellery-description-line" />
          <p class="jewellery-description-text">
            {{ jewelleryItem.description }}
          </p>
        </div>
        <div class="col-sm-4 offset-sm-4 jewellery-item-content">
          <p
            v-if="jewelleryItem.price"
            class="jewellery-item-text jewellery-item-price"
          >
            Price: &pound;{{ jewelleryItem.price }}
          </p>
          <div id="paypal-button"></div>
          <div data-pp-message :data-pp-amount="itemData.price"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import MainHeader from "@/components/MainHeader";
import app from "../firebase";
import "firebase/firestore";

export default {
  name: "Home",
  data() {
    return {
      itemData: {},
      statusMessage: "",
    };
  },
  computed: {
    jewelleryItem() {
      return this.itemData;
    },
  },
  components: {
    MainHeader,
  },
  async mounted() {
    let db = app.firestore();
    let $this = this;
    app
      .auth()
      .signInAnonymously()
      .then(async () => {
        let items = db.collection("jewellery_items");
        let jItem = await items.doc(this.$route.params.reference).get();

        this.itemData = jItem.data();
      });

    window.paypal
      .Buttons({
        createOrder(data, actions) {
          return actions.order.create({
            purchase_units: [
              {
                reference_id: $this.jewelleryItem.id,
                description:
                  $this.jewelleryItem.type + " " + $this.jewelleryItem.name,
                amount: {
                  currency_code: "GBP",
                  value: $this.jewelleryItem.price,
                },
              },
            ],
          });
        },
        onCancel(data) {
          console.warn("Cancelled...", data);
          window.scroll(0, 0);
        },
        onError(data) {
          console.error("Error", data);
          $this.statusMessage =
            "Error occurred while creating order.!" +
            " Please try again later!";
          window.scroll(0, 0);
        },
        onApprove(data, actions) {
          $this.itemData.orderID = data.orderID;
          $this.itemData.sold = true;
          db.collection("jewellery_items")
            .doc($this.$route.params.reference)
            .update($this.itemData)
            .then(() => {
              $this.statusMessage =
                "Thank you for the purchase of " +
                $this.jewelleryItem.name +
                "!";
            });

          window.scroll(0, 0);

          return actions.order.capture().then((details) => {
            $this.statusMessage =
              "The transaction is now completed by " +
              details.payer.name.given_name +
              "!" +
              " We will send more information on your email!";
          });
        },
      })
      .render("#paypal-button");
  },
};
</script>

<style scoped>
p,
a,
h1,
h2,
h3,
h4,
h5 {
  font-family: "Noto Serif", sans-serif;
}

.jewellry-item-container {
  padding-top: 12px;
}

p.jewellery-item-type {
  font-size: 10pt;
  text-align: center;
  margin-bottom: 2px;
}

p.status-message {
  text-align: center;
  font-size: 14pt;
  color: darkgreen;
}

p.jewellery-item-text {
  font-size: 10pt;
  text-align: center;
  margin-bottom: 2px;
}

.jewellery-item-image {
  max-width: 320px;
}

.jewellery-item-content {
  text-align: center;
  margin-bottom: 12px;
}

.jewellery-item-title {
  font-size: 18pt;
  font-weight: bold;
  margin-bottom: 2px;
}

.jewellery-type-label {
  font-weight: bold;
  font-size: 9pt;
  margin-bottom: 4px;
  text-transform: capitalize;
}

.jewellery-description-label {
  font-weight: bold;
  font-size: 10pt;
  margin-bottom: 0 !important;
  text-transform: capitalize;
}

.jewellery-description-line {
  margin: 0 0 2px;
}

.jewellery-item-price {
  font-weight: bold;
}
</style>
