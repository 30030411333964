import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import NotFound from "../views/NotFound.vue";
import Login from "../views/Admin/Login.vue";
import Dashboard from "../views/Admin/Dashboard.vue";
import ClientMessages from "../views/Admin/ClientMessages.vue";
import UploadFile from "../views/Admin/UploadFile.vue";
import PrintQR from "../views/Admin/PrintQR.vue";
import PrintCertificate from "../views/Admin/PrintCertificate.vue";
import JewelleryCollection from "../views/JewelleryCollection.vue";
import JewelleryItem from "../views/JewelleryItem.vue";

import ManageJewellery from "../views/Admin/ManageJewellery.vue";
import CreateJewelleryItem from "../views/Admin/CreateJewelleryItem.vue";
import EditJewelleryItem from "../views/Admin/EditJewelleryItem.vue";

import store from "../store";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/client-messages",
    name: "ClientMessages",
    component: ClientMessages,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/upload-file",
    name: "UploadFile",
    component: UploadFile,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/print-qr/:reference",
    name: "PrintQRCode",
    component: PrintQR,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/print-sapphire-certificate",
    name: "PrintSappCert",
    component: PrintCertificate,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/admin",
    name: "AdminRedirect",
    redirect: "/login",
  },
  {
    path: "/manage-jewellery",
    name: "ManageJewellery",
    component: ManageJewellery,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/create-jewellery-item",
    name: "CreateJewelleryItem",
    component: CreateJewelleryItem,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/edit-jewellery-item/:reference",
    name: "EditJewelleryItem",
    component: EditJewelleryItem,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/jewellery-collection",
    name: "JewelleryCollection",
    component: JewelleryCollection,
  },
  {
    path: "/jewellery-item/:reference",
    name: "JewelleryItem",
    component: JewelleryItem,
  },
  // {
  //   path: "/",
  //   name: "Main",
  //   component: Construction,
  // },
  {
    path: "/:pathMatch(.*)*",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((routeRecord) => routeRecord.meta.authRequired)) {
    if (!store.state.user) {
      next({
        path: "/login",
        query: {
          redirect: to.fullPath,
        },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
