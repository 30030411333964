<template>
  <div class="not-found">
    <h1>Ooops, seems that the page you requested does not exist!</h1>
    <router-link to="/">Home</router-link>
  </div>
</template>

<script>
export default {};
</script>

<style>
.not-found {
  padding-top: 23%;
  text-align: center;
}
.not-found h1,
.not-found a {
  color: lightgrey;
  text-align: center;
  text-decoration: none;
}
</style>
