<template>
  <div class="container upload-container">
    <div class="row title-box">
      <admin-navbar />
      <div class="col-xs-12 col-sm-8 offset-sm-2">
        <h2>Edit jewellery item</h2>
      </div>
    </div>
    <div class="row upload-container">
      <div class="col-sm-8 offset-sm-2">
        <div class="card">
          <div class="card-header">{{ statusMessage }}&nbsp;</div>
          <div class="card-body">
            <div v-if="itemData.imageUrl" class="item-image-holder">
              <img
                :src="itemData.imageUrl"
                alt="item-image"
                class="item-image"
              />
            </div>
            <form action="#" @submit.prevent="submit">
              <div class="form-group row file-upload-row">
                <label
                  for="name"
                  class="col-md-2 offset-md-1 col-form-label text-md-right"
                  >Name
                </label>
                <div class="col-md-6 reference-inupt-container">
                  <input
                    id="name"
                    class="form-control"
                    name="name"
                    v-model="itemData.name"
                    required
                    autofocus
                  />
                </div>
              </div>
              <div class="form-group row file-upload-row">
                <label
                  for="type"
                  class="col-md-2 offset-md-1 col-form-label text-md-right"
                  >Type
                </label>
                <div class="col-md-4 reference-inupt-container">
                  <select
                    id="type"
                    class="form-control"
                    name="type"
                    required
                    v-model="itemData.type"
                  >
                    <option disabled selected value="">Please Select</option>
                    <option value="bracelet">Bracelet</option>
                    <option value="earrings">Earrings</option>
                    <option value="gem">Gem</option>
                    <option value="neckless">Neckless</option>
                    <option value="ring">Ring</option>
                  </select>
                </div>
              </div>

              <div class="form-group row file-upload-row">
                <label
                  for="description"
                  class="col-md-2 offset-md-1 col-form-label text-md-right"
                  >Description
                </label>
                <div class="col-md-8 reference-inupt-container">
                  <textarea
                    id="description"
                    class="form-control"
                    name="description"
                    v-model="itemData.description"
                    rows="6"
                    required
                  ></textarea>
                </div>
              </div>
              <div class="form-group row file-upload-row">
                <label
                  for="name"
                  class="col-md-2 offset-md-1 col-form-label text-md-right"
                  >Price (&pound;)
                </label>
                <div class="col-md-3 reference-inupt-container">
                  <input
                    id="price"
                    type="number"
                    class="form-control"
                    name="price"
                    v-model="itemData.price"
                    autofocus
                  />
                </div>
              </div>
              <div class="form-group row file-upload-row">
                <label
                  for="file"
                  class="col-md-2 offset-md-1 col-form-label text-md-right"
                  >Image
                </label>
                <div class="col-md-6 login-input">
                  <input
                    id="image"
                    type="file"
                    class="form-control"
                    name="image"
                    @change="fileChanged"
                  />
                </div>
              </div>

              <div class="form-group row mb-0">
                <div class="col-md-1 offset-md-10 login-input">
                  <button
                    type="submit"
                    class="btn btn-sm btn-success"
                    @click="updateItem"
                  >
                    Update
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import app from "../../firebase";
import "firebase/storage";
import "firebase/firestore";

import AdminNavbar from "../../components/AdminNavbar.vue";

export default {
  name: "CreateJewelleryItem",
  data() {
    return {
      itemData: {},
      statusMessage: "",
      fileName: null,
      fileToUpload: null,
    };
  },
  components: {
    AdminNavbar,
  },
  computed: {
    ...mapGetters({
      user: "user",
      files: "files",
    }),
    jewelleryItem() {
      return this.itemData;
    },
  },
  async created() {
    let db = app.firestore();
    let items = db.collection("jewellery_items");
    let jItem = await items.doc(this.$route.params.reference).get();

    this.itemData = jItem.data();
  },
  methods: {
    fileChanged(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.fileName = files[0].name;
        if (this.fileName.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.fileUrl = fr.result;
          this.fileToUpload = files[0];
        });
      }
    },
    async updateItem() {
      if (this.itemName == "") {
        this.statusMessage = "Please provide valid name!";

        return;
      }

      let storageRef = app.storage().ref();
      let db = app.firestore();

      if (this.fileName && this.fileName !== "") {
        let imageName = Date.now() + this.fileName;
        let newRef = storageRef.child(`jewellery/${imageName}`);
        let snapshot = await newRef.put(this.fileToUpload);
        let newDowlnloadUrl = await snapshot.ref.getDownloadURL();

        this.itemData.imageUrl = newDowlnloadUrl;
        this.itemData.imageName = imageName;
      }

      db.collection("jewellery_items")
        .doc(this.$route.params.reference)
        .update(this.itemData)
        .then(() => {
          this.statusMessage =
            "The jewellery item has been successfully updated!";

          setTimeout(() => {
            this.statusMessage = "";
          }, 2400);
        });
    },
  },
};
</script>

<style scoped>
button {
  font-weight: bold !important;
}
.right-button {
  float: right;
}
.file-upload-row {
  padding: 6px;
}
.reference-inupt-container {
  padding-left: 4px;
}
.title-box {
  margin-bottom: 24px;
}
.item-image-holder {
  text-align: center;
  margin-bottom: 12px;
}
.item-image {
  max-width: 164px;
}
</style>
