<template>
  <div class="row" id="contacts">
    <div class="col-xs-12 contacts-container">
      <div class="row">
        <div class="col-sm-4 offset-sm-4">
          <h2 class="contacts-title">Contact</h2>
          <p class="contact-label">
            Get in contact with us for any custom enqueries and our team will
            provide all needed information.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 offset-sm-4">
          <p class="contact-label">{{ actionStatus }}</p>
        </div>
      </div>

      <form action="#" @submit.prevent="submit" v-if="!submitted">
        <div class="row">
          <div class="col-sm-2 offset-sm-4">
            <label
              for="reference"
              class="col-xs-12 col-sm-2 col-form-label text-md-right contact-label"
              >Reference</label
            >
            <div class="input-group input-group-sm col-md-1 login-input">
              <input
                id="contact_reference"
                type="reference"
                class="form-control input-sm"
                name="reference"
                v-model="reference"
                value
                required
              />
            </div>
          </div>
          <div class="col-sm-2">
            <label
              for="email"
              class="col-xs-12 col-sm-2 col-form-label text-md-right contact-label"
              >Email</label
            >
            <div class="input-group input-group-sm col-md-2 login-input">
              <input
                id="email"
                type="email"
                class="form-control input-sm"
                name="email"
                v-model="contactEmail"
                value
                required
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-4 offset-sm-4">
            <label
              for="email"
              class="col-xs-12 col-sm-2 col-form-label text-md-right contact-label"
              >Enquiry</label
            >
            <div class="input-group input-group-sm col-md-2 login-input">
              <textarea
                id="message"
                type="message"
                class="form-control input-sm"
                name="message"
                v-model="message"
                required
              ></textarea>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 offset-sm-4">
            <div class="input-group input-group-sm col-md-2 login-input">
              <button class="btn btn-outline-light" @click="storeMessage()">
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import app from "../firebase";
import firebase from "firebase/app";
import "firebase/firestore";

const db = app.firestore();

export default {
  name: "Dashboard",
  data() {
    return {
      reference: null,
      contactEmail: null,
      message: "",
      actionStatus: "",
      sumbitted: false,
    };
  },
  methods: {
    storeMessage() {
      if (this.reference === null || this.reference.length < 3) {
        this.actionStatus = "Please provide reference!";

        return;
      }

      if (this.contactEmail === null || this.contactEmail.length < 3) {
        this.actionStatus = "Please provide contact email!";

        return;
      }

      if (this.message === null || this.message.length < 3) {
        this.actionStatus = "Please provide message content!";

        return;
      }

      db.collection("clientMessage")
        .add({
          reference: this.reference,
          contactEmail: this.contactEmail,
          message: this.message,
          timeSubmitted: firebase.firestore.Timestamp.fromDate(new Date()),
        })
        .then(() => {
          this.submitted = true;
          this.actionStatus =
            "Your message was successfully delivered. We will contact you soon!";
        })
        .catch((error) => {
          console.log(error);
          this.actionStatus =
            "Your message was not delivered. Please try again later.";
        });
    },
  },
};
</script>

<style>
.contacts-title,
.contact-label {
  color: white;
}
.contacts-container {
  background-color: #0f0f0e;
  min-height: 400px;
  padding: 15px;
}
</style>
