<template>
  <div id="about-us">
    <div class="row info-paragraph-holder">
      <div class="col-sm-6 offset-sm-3 info-paragraph">
        <h2>About us</h2>
        <p style="font-style: italic">SGJ independent gemologist located in Kingston upon Thames dedicated to the art and science of gemstones. With a passion for uncovering the hidden stories within each gem, combines extensive expertise with a keen eye for detail to deliver unparalleled gemological services.
<br />As an independent professional, brings a unique perspective to the world of gemstones, offering unbiased evaluations, appraisals, and consultations. Whether you're a collector, jeweler, or enthusiast, with comprehensive knowledge and commitment to excellence ensure that you receive the highest quality insights and services tailored to your needs.
<br />With a background in gemological education and years of hands-on experience, is adept at identifying, grading, and valuing a wide variety of gemstones. Utilizing state-of-the-art equipment and adhering to the strictest industry standards, provides accurate and reliable assessments that you can trust.
<br />
<br />Discover the brilliance and beauty of gemstones with your trusted independent gemologist.</p>
      </div>
    </div>
  </div>
</template>

<style>
.info-paragraph-holder {
  background-color: #fff;
}
</style>
