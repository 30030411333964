<template>
  <div class="row services-section">
    <div class="col-sm-8 offset-sm-2 jewellry-evaluation">
      <h3>Jewellery valuation</h3>
      <p>
        Jewellery valuation is highly specialised profession, requiring the
        combined in-depth knowledge of the methods of jewellery manufacture,
        both historic and current; historic and contemporary design styles, the
        principal designers and their workshops; gemstone identification
        (Gemmology) and diamond grading; and a comprehensive understanding of
        the precious metals and diamond markets and the jewellery retail and
        auction market sectors.
      </p>
    </div>
    <br />
    <div class="col-sm-3">
      <div class="card ard w-100">
        <img class="card-img-top" alt="..." src="../assets/gemology.jpg" />
        <div class="card-body">
          <h5 class="card-title">Gemmology</h5>
          <p class="card-text">
            Gemology is the science of studying, cutting, and valuing precious
            stones, but the essence of gemology is in identifying the gemstones.
          </p>
          <!-- <a href="#" class="btn btn-primary">Go somewhere</a> -->
        </div>
      </div>
    </div>
    <div class="col-sm-3">
      <div class="card ard w-100">
        <img class="card-img-top" alt="..." src="../assets/valuation.png" />
        <div class="card-body">
          <h5 class="card-title">Valuation</h5>
          <p class="card-text">
            We offer qualified appraisal services for jewellery, gemstones,
            watches and provide certificate of valuaton as a document to assure
            the quality of each stone.
          </p>
          <!-- <a href="#" class="btn btn-primary">Go somewhere</a> -->
        </div>
      </div>
    </div>
    <div class="col-sm-3">
      <div class="card ard w-100">
        <img
          class="card-img-top"
          alt="..."
          src="../assets/identification.png"
        />
        <div class="card-body">
          <h5 class="card-title">Gem Identification</h5>
          <p class="card-text">
            Gem identification is basically a process of elimination. Gemstones
            of similar color undergo non-destructive optical testing until there
            is only one possible identity.
          </p>
          <!-- <a href="#" class="btn btn-primary">Go somewhere</a> -->
        </div>
      </div>
    </div>
    <div class="col-sm-3">
      <div class="card w-100">
        <img
          class="card-img-top"
          alt="Consultation"
          src="../assets/consultation.png"
        />
        <div class="card-body">
          <h5 class="card-title">Market consultations</h5>
          <p class="card-text">
            We provide market consultations to help our clients understand what
            the market can deliver for their precious and beloved jewellery.
          </p>
          <!-- <a href="#" class="btn btn-primary">Go somewhere</a> -->
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.services-section {
  background-color: #fff;
}
.jewellry-evaluation {
  text-align: center;
}
</style>
