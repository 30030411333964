import firebase from "firebase/app";
import "firebase/auth";
import store from "./store";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const config = {
  apiKey: "AIzaSyApt6rLPmTPevH60PpbDLHtZlnIURv9CgE",
  authDomain: "perfect-garden-315516.firebaseapp.com",
  projectId: "perfect-garden-315516",
  storageBucket: "perfect-garden-315516.appspot.com",
  messagingSenderId: "71416308297",
  appId: "1:71416308297:web:0e09bed578df5e88915598",
  measurementId: "G-N9D9TNXSK3",
};

const app = firebase.initializeApp(config);

app.auth().onAuthStateChanged((user) => {
  store.commit("SET_USER", user);
});

export default app;
