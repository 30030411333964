import { createStore } from "vuex";

export default createStore({
  state: {
    user: null,
    files: {},
  },
  getters: {
    user(state) {
      return state.user;
    },
    files(state) {
      return state.files;
    },
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    SET_FILES(state, files) {
      state.files = files;
    },
    ADD_FILE(state, details) {
      let currentFiles = { ...state.files };
      currentFiles[details.id] = { ...details.data };

      state.files = currentFiles;
    },
  },
  actions: {
    removeFileById({ commit, state }, id) {
      let newFiles = {};
      Object.keys(state.files).forEach((fileKey) => {
        if (id !== fileKey) {
          newFiles[fileKey] = { ...state.files[fileKey] };
        }
      });

      commit("SET_FILES", newFiles);
    },
    addFile({ commit }, details) {
      commit("ADD_FILE", details);
    },
    addFiles({ commit }, files) {
      commit("SET_FILES", files);
    },
    setUser({ commit }, user) {
      commit("SET_USER", user);
    },
  },
});
