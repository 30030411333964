<template>
  <div class="logos-holder">
    <img
      src="../assets/GIA/ALUM170008_Alumni_Association_Member__CMYK_Vert.png"
      class="d-block gia-logo"
      alt="GIA logo"
    />
  </div>
</template>
<style>
img.gia-logo {
  margin: 0 auto;
  max-width: 42%;
}
div.logos-holder {
  width: 100%;
  text-align: center;
}
</style>
