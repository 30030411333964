<template>
  <div class="home">
    <MainHeader />
    <Carousel />
    <MainDetails />
    <div class="parallax"></div>
    <ServicesSection />
    <div class="parallax"></div>
    <ReportCheck />
    <Certification />
    <ContactSection />
  </div>
</template>

<script>
// @ is an alias to /src
import MainHeader from "@/components/MainHeader";
import ReportCheck from "@/components/ReportCheck";
import Carousel from "@/components/Carousel";
import MainDetails from "@/components/MainDetails";
import ServicesSection from "@/components/ServicesSection";
import ContactSection from "@/components/ContactSection";
import Certification from "@/components/Certification";

export default {
  name: "Home",
  components: {
    MainHeader,
    ReportCheck,
    Carousel,
    MainDetails,
    ServicesSection,
    ContactSection,
    Certification,
  },
};
</script>

<style>
p,
a,
h1,
h2,
h3,
h4,
h5 {
  font-family: "Noto Serif", sans-serif;
}
.services-section {
  padding: 15px;
}
.info-paragraph {
  padding: 15px;
  text-align: center;
}

.parallax {
  /* The image used */
  background-image: url("../assets/diamond.jpg");

  /* Set a specific height */
  min-height: 500px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
